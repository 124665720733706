var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":"1100px","persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"item-value":'id',"items":_vm.products,"filter":_vm.customFilter,"label":"Produit","rules":[function (v) { return !!v || 'Produit obligatoire'; }],"dense":""},on:{"change":_vm.produit_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.ref ? item.ref + "-" : "") + (item.product_code ? item.product_code + "-" : "") + item.product_label)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                          (item.ref ? item.ref + '-' : '') +
                          (item.product_code ? item.product_code + '-' : '') +
                          item.product_label
                        )}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                          'Qte Commandée ' +
                          (item.qte ? item.qte : 0) +
                          ' ' +
                          (item.unit ? item.unit : '')
                        )}})],1)]}}]),model:{value:(_vm.editedItem.cmd_detail_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "cmd_detail_id", $$v)},expression:"editedItem.cmd_detail_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-autocomplete',{attrs:{"item-value":_vm.article_field,"items":_vm.articles_list,"filter":_vm.customFilter,"label":"Article","rules":[function (v) { return !!v || 'Article obligatoire'; }],"dense":"","loading":_vm.loading},on:{"change":_vm.article_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item ? (item.article_code ? item.article_code + "-" : "") + (item.article_label ? item.article_label : _vm.editedItem.product_label) + (item.ref_ext ? "-" + item.ref_ext : "") : _vm.editedItem.label)+" ")]}},{key:"item",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s((item.article_code ? item.article_code + "-" : "") + (item.article_label ? item.article_label : _vm.editedItem.product_label) + (item.ref_ext ? "-" + item.ref_ext : ""))+" ")]}}]),model:{value:(_vm.editedItem.article_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "article_id", $$v)},expression:"editedItem.article_id"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{ref:"pu",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","readonly":_vm.readonly,"label":'Prix ',"hide-spin-buttons":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.qte.focus()},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.pu),callback:function ($$v) {_vm.$set(_vm.editedItem, "pu", _vm._n($$v))},expression:"editedItem.pu"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{ref:"qte",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","readonly":_vm.readonly,"label":_vm.editedItem.unit_name ? _vm.editedItem.unit_name : 'Quantité',"required":"","rules":[
                    function (v) { return !!v ||
                      (_vm.editedItem.unit_name
                        ? _vm.editedItem.unit_name
                        : 'Quantité') + ' obligatoire'; },
                    function (v) { return !v || v > 0 || 'Valeur incorrecte'; },
                    function (v) { return parseFloat(v) <= parseFloat(_vm.qte_max) ||
                      'Maximum autorisé ' + _vm.qte_max; } ],"disabled":_vm.save_disable,"hide-spin-buttons":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.qte),callback:function ($$v) {_vm.$set(_vm.editedItem, "qte", $$v)},expression:"editedItem.qte"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"10"}},[_c('v-text-field',{ref:"comment",attrs:{"autocomplete":"off","dense":"","label":"Commentaire","readonly":_vm.readonly},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',{attrs:{"fill-height":""}},[_c('v-alert',{attrs:{"type":_vm.type_alert,"dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(_vm._s(_vm.message))]),_c('v-spacer'),(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e()],1),_c('v-spacer'),(!_vm.readonly)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.save_disable},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Fermer ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }